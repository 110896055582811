import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { GlobalContext } from '../../app/context/glogal'



const Filter = () => {
    const { filters, setFilters, techonologies, data, setView, setPowerplants } = useContext(GlobalContext)
    
    /**
     * Filter toggle
     */
    const [ filterDropdown, setFilterDropdown ] = useState(false)
    const filterDropdownElement = useRef()
    const filterDropdownBackdrop = useCallback(({ target }) => {
        if (filterDropdownElement && !filterDropdownElement.current?.contains(target)) setFilterDropdown(false)
    }, [])
    useEffect(() => {
        if (!filterDropdownElement) return window.removeEventListener('mousedown', filterDropdownBackdrop)
        window.addEventListener('mousedown', filterDropdownBackdrop)
        return () => {
            window.removeEventListener('mousedown', filterDropdownBackdrop)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * Technologies
     */
    const toggleTechnologies = useCallback((index) => {
        const newFilters = { ...filters }
        if (filters.technologies.includes(index)) {
            newFilters.technologies.splice(newFilters.technologies.indexOf(index), 1)
        } else {
            newFilters.technologies.push(index)
        }
        setFilters(newFilters)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters])

    /**
     * Power
     */
    const togglePowers = useCallback((name) => {
        const newFilters = { ...filters }
        if (filters.powers.includes(name)) {
            newFilters.powers.splice(filters.powers.indexOf(name), 1)
        } else {
            newFilters.powers.push(name)
        }
        setFilters(newFilters)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters])

    /**
     * View
     */
    const toggleView = useCallback((type, technology) => {
        const newView = { type }
        if (type === 'ordered') {
            if (filters.view.type === 'ordered')// Toggle
                return setFilters({ ...filters, view: { type, order: filters.view.order === 'ASC' ? 'DESC' : 'ASC' }})
            else return setFilters({ ...filters, view: { type, order: 'ASC' }})
        }
        if (type === 'calendar') {
            return setFilters({ ...filters, view: { type }})
        }
        return setFilters({ ...filters, view: { type, technology }})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters])

    /**
     * Toggle views
     */
    const [ viewSelectorDropdown, setViewSelectorDropdown ] = useState(false)
    const viewSelectorElement = useRef()
    const viewSelectorBackdrop = useCallback(({ target }) => {
        if (viewSelectorElement && !viewSelectorElement.current?.contains(target)) setViewSelectorDropdown(false)
    }, [])
    useEffect(() => {
        if (!viewSelectorElement) return window.removeEventListener('mousedown', viewSelectorBackdrop)
        window.addEventListener('mousedown', viewSelectorBackdrop)
        return () => {
            window.removeEventListener('mousedown', viewSelectorBackdrop)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

   
    /**
     * Render
     */
    return (
        <nav className="filter">
            <div className="filter-wrapper">
                {/* Search */}
                <div className="filter-search">
                    <label className="filter-search-label" htmlFor="filterSearch">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M507.312 484.688L365.85 343.227C397.041 306.848 416 259.676 416 208C416 93.125 322.875 0 208 0S0 93.125 0 208S93.125 416 208 416C259.676 416 306.848 397.039 343.225 365.852L484.687 507.312C487.812 510.438 491.906 512 496 512S504.188 510.438 507.312 507.312C513.562 501.062 513.562 490.938 507.312 484.688ZM208 384C110.953 384 32 305.047 32 208S110.953 32 208 32S384 110.953 384 208S305.047 384 208 384Z"/></svg>                        
                    </label>
                    <input 
                        type="text" 
                        id="filterSearch" 
                        className="filter-search-input"
                        value={filters.search}
                        placeholder="Rechercher dans les centrales"
                        onChange={({ target }) => setFilters({ ...filters, search: target.value})}
                    />
                    <button 
                        className="filter-search-clear"
                        onClick={() => setFilters({ ...filters, search: ''})}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M315.31 411.31C309.056 417.563 298.936 417.563 292.682 411.31L160 278.627L27.318 411.31C21.064 417.563 10.944 417.563 4.69 411.31C-1.563 405.056 -1.563 394.936 4.69 388.682L137.373 256L4.69 123.318C-1.563 117.064 -1.563 106.944 4.69 100.69C10.944 94.437 21.064 94.437 27.318 100.69L160 233.373L292.682 100.69C298.936 94.437 309.056 94.437 315.31 100.69C321.563 106.944 321.563 117.064 315.31 123.318L182.627 256L315.31 388.682C321.563 394.936 321.563 405.056 315.31 411.31Z"/></svg>                        </button>
                </div>

                {/* Filters */}
                <div className="filter-dropdown-wrapper" ref={filterDropdownElement}>
                    <button 
                        className="filter-button secondary"
                        onClick={() => setFilterDropdown(!filterDropdown)}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M507.71 58.707C500.023 42.232 483.786 32 465.337 32H46.654C28.204 32 11.968 42.232 4.283 58.707C-3.196 74.734 -0.758 93.158 10.644 106.787L175.995 309.912V377.619C175.995 390.166 182.173 401.922 192.519 409.066L274.835 472.617C281.997 477.613 289.578 480 297.408 480C318.685 480 335.995 462.807 335.995 441.67V309.912L501.486 106.623C512.749 93.158 515.187 74.734 507.71 58.707ZM295.187 283.986C290.535 289.699 287.995 296.844 287.995 304.215V422.113L223.995 372.725V304.215C223.995 296.844 221.456 289.699 216.804 283.986L50.773 80.037H461.214L295.187 283.986Z"/></svg>
                    </button>
                    {filterDropdown && (
                        <div className="filter-dropdown">
                            <h2 className="filter-dropdown-title">Technologies :</h2>
                            <ul className="filter-dropdown-list">
                                {techonologies.map((technologie, index) => (
                                    <li key={`FilterTechnologie${index}`} className="filter-dropdown-list-item">
                                        <button
                                                className="filter-dropdown-list-item-button"
                                                onClick={() => toggleTechnologies(index)}
                                        >
                                            <span className="filter-dropdown-list-item-button-icon">
                                                {filters.technologies.includes(index) ? (
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M572.531 238.973C518.281 115.525 410.938 32 288 32S57.688 115.58 3.469 238.973C1.562 243.402 0 251.041 0 256C0 260.977 1.562 268.596 3.469 273.025C57.719 396.473 165.062 480 288 480S518.312 396.418 572.531 273.025C574.438 268.596 576 260.957 576 256C576 251.023 574.438 243.402 572.531 238.973ZM288 432C188.521 432 96.836 364.502 48.424 256.004C97.01 147.365 188.611 80 288 80C387.48 80 479.164 147.498 527.576 255.994C478.99 364.635 387.389 432 288 432ZM288 128C217.334 128 160 185.348 160 256S217.334 384 288 384H288.057C358.695 384 416 326.68 416 256.055V256C416 185.348 358.668 128 288 128ZM288 336C243.889 336 208 300.111 208 256C208 255.252 208.199 254.559 208.221 253.816C213.277 255.125 218.52 256 224 256C259.346 256 288 227.346 288 192C288 186.52 287.125 181.277 285.816 176.221C286.559 176.199 287.252 176 288 176C332.111 176 368 211.889 368 256.055C368 300.137 332.137 336 288 336Z"/></svg>
                                                ) : (
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M630.81 469.102L527.37 388.027C558.716 356.086 585.111 317.219 604.53 273.025C606.437 268.596 607.999 260.957 607.999 256C607.999 251.023 606.437 243.402 604.53 238.973C550.28 115.525 442.937 32 319.999 32C257.331 32 198.921 54.066 149.343 91.738L38.814 5.109C34.407 1.672 29.189 0 24.032 0C16.907 0 9.845 3.156 5.126 9.188C-3.061 19.625 -1.249 34.717 9.189 42.889L601.185 506.883C611.685 515.086 626.747 513.211 634.872 502.805C643.06 492.367 641.247 477.273 630.81 469.102ZM394.394 283.805L312.741 219.807C316.968 211.324 319.999 202.15 319.999 192C319.999 186.52 319.124 181.277 317.816 176.221C318.558 176.199 319.251 176 319.999 176C364.111 176 399.999 211.889 399.999 256.055C399.999 265.91 397.677 275.088 394.394 283.805ZM433.206 314.225C442.386 296.764 447.999 277.188 447.999 256.055V256C447.999 185.348 390.667 128 319.999 128C287.792 128 258.687 140.172 236.329 159.916L188.314 122.285C228.015 95.033 273.126 80 319.999 80C419.48 80 511.163 147.498 559.575 255.994C541.515 296.379 517.165 330.428 488.968 357.93L433.206 314.225ZM320.056 384C333.474 384 346.157 381.357 358.312 377.523L302.833 334.041C279.054 328.809 259.454 312.885 248.775 291.67L192.825 247.818C192.648 250.576 191.999 253.197 191.999 256C191.999 326.652 249.333 384 319.999 384H320.056ZM319.999 432C220.521 432 128.835 364.502 80.423 256.004C91.257 231.779 104.515 209.975 119.238 190.143L81.277 160.391C63.509 184.133 48.011 210.426 35.468 238.973C33.562 243.402 31.999 251.041 31.999 256C31.999 260.977 33.562 268.596 35.468 273.025C89.718 396.473 197.062 480 319.999 480C365.458 480 408.763 468.51 448.064 447.867L405.204 414.275C378.03 425.434 349.458 432 319.999 432Z"/></svg>
                                                )}
                                            </span>
                                            <span>{technologie}</span>
                                        </button>
                                    </li>
                                ))}
                            </ul>
                            <h2 className="filter-dropdown-title">Puissance :</h2>
                            <div className="filter-dropdown-power">
                                <div className="filter-dropdown-power-grid">
                                    <button 
                                        className={`filter-dropdown-power-1 ${filters.powers.includes('500') ? 'active' : ''}`}
                                        onClick={() => togglePowers('500')}
                                    >
                                        &lt; 500kW
                                    </button>
                                    <button 
                                        className={`filter-dropdown-power-2 ${filters.powers.includes('500-2500') ? 'active' : ''}`}
                                        onClick={() => togglePowers('500-2500')}
                                    >
                                        500kW - 2500kW
                                    </button>
                                    <button 
                                        className={`filter-dropdown-power-3 ${filters.powers.includes('2500') ? 'active' : ''}`}
                                        onClick={() => togglePowers('2500')}
                                    >
                                        &gt; 2500kW
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                {/* Technology views */}
                <div className="filter-dropdown-wrapper" ref={viewSelectorElement}>
                    <button 
                        
                        className={`filter-button${filters.view.type === 'technology' ? ' active' : ''}`}
                        onClick={() => setViewSelectorDropdown(!viewSelectorDropdown)}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M0 144V368C0 394.508 21.49 416 48 416V96C21.49 96 0 117.492 0 144ZM96 96V416C96 442.508 117.49 464 144 464V48C117.49 48 96 69.492 96 96ZM512 0H256C220.654 0 192 28.652 192 64V448C192 483.348 220.654 512 256 512H512C547.346 512 576 483.348 576 448V64C576 28.652 547.346 0 512 0ZM528 448C528 456.836 520.836 464 512 464H256C247.164 464 240 456.836 240 448V64C240 55.162 247.164 48 256 48H512C520.836 48 528 55.162 528 64V448Z"/></svg>
                    </button>
                    {viewSelectorDropdown && (
                        <div className="filter-dropdown">
                            <h2 className="filter-dropdown-title">Technologies :</h2>
                            <ul className="filter-dropdown-list">
                                {techonologies.map((technology, index) => (
                                    <li key={`FilterTechnologie${index}`} className="filter-dropdown-list-item">
                                        <button
                                                className="filter-dropdown-list-item-button"
                                                onClick={() => toggleView('technology', index)}
                                        >
                                            <img src={`/images/powerplants/technology-${index}.png`} alt={technology} />
                                            <span>{technology}</span>
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>

                {/* Calendar view */}
                <button 
                    className={`filter-button${filters.view.type === 'calendar' ? ' active' : ''}`}
                    onClick={() => toggleView('calendar')}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M400 64H352V32C352 14.4 337.6 0 320 0H320C302.4 0 288 14.4 288 32V64H160V32C160 14.4 145.6 0 128 0H128C110.4 0 96 14.4 96 32V64H48C21.49 64 0 85.49 0 112V160H448V112C448 85.49 426.51 64 400 64ZM0 464C0 490.5 21.5 512 48 512H400C426.5 512 448 490.5 448 464V192H0V464Z"/></svg>
                </button>

            </div>
        </nav>
    )
}
export default Filter

/*
                <div className={styles.dropdownWrapper} ref={filterDropdownElement}>
                    <button 
                        className={styles.filterToggleButton}
                        onClick={() => setFilterDropdown(!filterDropdown)}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M507.71 58.707C500.023 42.232 483.786 32 465.337 32H46.654C28.204 32 11.968 42.232 4.283 58.707C-3.196 74.734 -0.758 93.158 10.644 106.787L175.995 309.912V377.619C175.995 390.166 182.173 401.922 192.519 409.066L274.835 472.617C281.997 477.613 289.578 480 297.408 480C318.685 480 335.995 462.807 335.995 441.67V309.912L501.486 106.623C512.749 93.158 515.187 74.734 507.71 58.707ZM295.187 283.986C290.535 289.699 287.995 296.844 287.995 304.215V422.113L223.995 372.725V304.215C223.995 296.844 221.456 289.699 216.804 283.986L50.773 80.037H461.214L295.187 283.986Z"/></svg>
                    </button>
                    {filterDropdown && (
                        <div className={styles.dropdown}>
                            <h2 className={styles.dropdownTitle}>Technologies :</h2>
                            <ul className={styles.dropdownList}>
                                {techonologies.map((technologie, index) => (
                                    <li key={`FilterTechnologie${index}`} className={styles.dropdownListItem}>
                                        <button
                                                className={styles.dropdownListItemButton}
                                                onClick={() => toggleTechnologies(index)}
                                        >
                                            {filters.technologies.includes(index) ? (
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M572.531 238.973C518.281 115.525 410.938 32 288 32S57.688 115.58 3.469 238.973C1.562 243.402 0 251.041 0 256C0 260.977 1.562 268.596 3.469 273.025C57.719 396.473 165.062 480 288 480S518.312 396.418 572.531 273.025C574.438 268.596 576 260.957 576 256C576 251.023 574.438 243.402 572.531 238.973ZM288 432C188.521 432 96.836 364.502 48.424 256.004C97.01 147.365 188.611 80 288 80C387.48 80 479.164 147.498 527.576 255.994C478.99 364.635 387.389 432 288 432ZM288 128C217.334 128 160 185.348 160 256S217.334 384 288 384H288.057C358.695 384 416 326.68 416 256.055V256C416 185.348 358.668 128 288 128ZM288 336C243.889 336 208 300.111 208 256C208 255.252 208.199 254.559 208.221 253.816C213.277 255.125 218.52 256 224 256C259.346 256 288 227.346 288 192C288 186.52 287.125 181.277 285.816 176.221C286.559 176.199 287.252 176 288 176C332.111 176 368 211.889 368 256.055C368 300.137 332.137 336 288 336Z"/></svg>
                                            ) : (
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M630.81 469.102L527.37 388.027C558.716 356.086 585.111 317.219 604.53 273.025C606.437 268.596 607.999 260.957 607.999 256C607.999 251.023 606.437 243.402 604.53 238.973C550.28 115.525 442.937 32 319.999 32C257.331 32 198.921 54.066 149.343 91.738L38.814 5.109C34.407 1.672 29.189 0 24.032 0C16.907 0 9.845 3.156 5.126 9.188C-3.061 19.625 -1.249 34.717 9.189 42.889L601.185 506.883C611.685 515.086 626.747 513.211 634.872 502.805C643.06 492.367 641.247 477.273 630.81 469.102ZM394.394 283.805L312.741 219.807C316.968 211.324 319.999 202.15 319.999 192C319.999 186.52 319.124 181.277 317.816 176.221C318.558 176.199 319.251 176 319.999 176C364.111 176 399.999 211.889 399.999 256.055C399.999 265.91 397.677 275.088 394.394 283.805ZM433.206 314.225C442.386 296.764 447.999 277.188 447.999 256.055V256C447.999 185.348 390.667 128 319.999 128C287.792 128 258.687 140.172 236.329 159.916L188.314 122.285C228.015 95.033 273.126 80 319.999 80C419.48 80 511.163 147.498 559.575 255.994C541.515 296.379 517.165 330.428 488.968 357.93L433.206 314.225ZM320.056 384C333.474 384 346.157 381.357 358.312 377.523L302.833 334.041C279.054 328.809 259.454 312.885 248.775 291.67L192.825 247.818C192.648 250.576 191.999 253.197 191.999 256C191.999 326.652 249.333 384 319.999 384H320.056ZM319.999 432C220.521 432 128.835 364.502 80.423 256.004C91.257 231.779 104.515 209.975 119.238 190.143L81.277 160.391C63.509 184.133 48.011 210.426 35.468 238.973C33.562 243.402 31.999 251.041 31.999 256C31.999 260.977 33.562 268.596 35.468 273.025C89.718 396.473 197.062 480 319.999 480C365.458 480 408.763 468.51 448.064 447.867L405.204 414.275C378.03 425.434 349.458 432 319.999 432Z"/></svg>
                                            )}
                                            <span>{technologie}</span>
                                        </button>
                                    </li>
                                ))}
                            </ul>
                            <h2 className={styles.dropdownTitle}>Puissance :</h2>
                            <div className={styles.filterToggleDropdownPower}>
                                <div className={styles.filterToggleDropdownPowerGrid}>
                                    <button 
                                        className={[
                                            styles.filterToggleDropdownPower1,
                                            filters.powers.includes('500') ? styles.active : ''
                                        ].join(' ')}
                                        onClick={() => togglePowers('500')}
                                    >
                                        &lt; 500kW
                                    </button>
                                    <button 
                                        className={[
                                            styles.filterToggleDropdownPower2,
                                            filters.powers.includes('500-2500') ? styles.active : ''
                                        ].join(' ')}
                                        onClick={() => togglePowers('500-2500')}
                                    >
                                        500kW - 2500kW
                                    </button>
                                    <button 
                                        className={[
                                            styles.filterToggleDropdownPower3,
                                            filters.powers.includes('2500') ? styles.active : ''
                                        ].join(' ')}
                                        onClick={() => togglePowers('2500')}
                                    >
                                        &gt; 2500kW
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className={styles.dropdownWrapper} ref={viewSelectorElement}>
                    <button 
                        className={styles.viewSelectorButton}
                        onClick={() => setViewSelectorDropdown(!viewSelectorDropdown)}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M0 144V368C0 394.508 21.49 416 48 416V96C21.49 96 0 117.492 0 144ZM96 96V416C96 442.508 117.49 464 144 464V48C117.49 48 96 69.492 96 96ZM512 0H256C220.654 0 192 28.652 192 64V448C192 483.348 220.654 512 256 512H512C547.346 512 576 483.348 576 448V64C576 28.652 547.346 0 512 0ZM528 448C528 456.836 520.836 464 512 464H256C247.164 464 240 456.836 240 448V64C240 55.162 247.164 48 256 48H512C520.836 48 528 55.162 528 64V448Z"/></svg>
                    </button>
                    {viewSelectorDropdown && (
                        <div className={styles.dropdown}>
                            <h2 className={styles.dropdownTitle}>Technologies :</h2>
                            <ul className={styles.dropdownList}>
                                {techonologies.map((technology, index) => (
                                    <li key={`FilterTechnologie${index}`} className={styles.dropdownListItem}>
                                        <button
                                                className={styles.dropdownListItemButton}
                                                onClick={() => toggleView('technology', index)}
                                        >
                                            <img
                                                src={`/images/powerplants/technology-${index}.png`} 
                                                alt="LuxEnergie"
                                            />
                                            <span>{technology}</span>
                                        </button>
                                    </li>
                                ))}
                            </ul>

                        </div>
                    )}
                </div>
                <div className={styles.filterViews}>
                    <button 
                        className={[
                            styles.filterViewsButton,
                            filters.view.type === 'ordered' ? styles.active : '',
                        ].join(' ')}
                        onClick={() => toggleView('ordered')}
                    >
                        {filters.view.order === 'DESC' ? (
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M415.998 288H320C302.326 288 288 302.328 288 320S302.326 352 320 352H415.998C433.671 352 447.998 337.672 447.998 320S433.671 288 415.998 288ZM352 416H320C302.326 416 288 430.328 288 448S302.326 480 320 480H352C369.673 480 384 465.672 384 448S369.673 416 352 416ZM479.998 160H320C302.326 160 288 174.328 288 192S302.326 224 320 224H479.998C497.671 224 511.998 209.672 511.998 192S497.671 160 479.998 160ZM543.998 32H320C302.326 32 288 46.328 288 64S302.326 96 320 96H543.998C561.671 96 575.998 81.672 575.998 64S561.671 32 543.998 32ZM151.593 41.945C139.468 28.684 116.531 28.684 104.406 41.945L16.408 138.039C4.472 151.086 5.347 171.352 18.377 183.305C24.517 188.934 32.267 191.719 39.986 191.719C48.658 191.719 57.283 188.215 63.595 181.332L96 145.938V447.969C96 465.672 110.328 480 128 480S160 465.672 160 447.969V145.938L192.404 181.332C204.357 194.344 224.607 195.25 237.623 183.305C250.652 171.352 251.527 151.086 239.591 138.039L151.593 41.945Z"/></svg>
                            ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M320 224H415.998C433.671 224 447.998 209.672 447.998 192S433.671 160 415.998 160H320C302.326 160 288 174.328 288 192S302.326 224 320 224ZM320 352H479.998C497.671 352 511.998 337.672 511.998 320S497.671 288 479.998 288H320C302.326 288 288 302.328 288 320S302.326 352 320 352ZM320 96H352C369.673 96 384 81.672 384 64S369.673 32 352 32H320C302.326 32 288 46.328 288 64S302.326 96 320 96ZM543.998 416H320C302.326 416 288 430.328 288 448S302.326 480 320 480H543.998C561.671 480 575.998 465.672 575.998 448S561.671 416 543.998 416ZM192.404 330.664L160 366.062V64.031C160 46.328 145.671 32 128 32S96 46.328 96 64.031V366.063L63.595 330.664C57.283 323.781 48.658 320.281 39.986 320.281C32.267 320.281 24.517 323.063 18.377 328.695C5.347 340.648 4.472 360.914 16.408 373.961L104.406 470.055C116.531 483.313 139.468 483.313 151.593 470.055L239.591 373.961C251.527 360.914 250.652 340.648 237.623 328.695C224.607 316.75 204.357 317.656 192.404 330.664Z"/></svg>
                        )}
                    </button>
                    <button 
                        className={[
                            styles.filterViewsButton,
                            filters.view.type === 'calendar' ? styles.active : '',
                        ].join(' ')}
                        onClick={() => toggleView('calendar')}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M400 64H352V32C352 14.4 337.6 0 320 0H320C302.4 0 288 14.4 288 32V64H160V32C160 14.4 145.6 0 128 0H128C110.4 0 96 14.4 96 32V64H48C21.49 64 0 85.49 0 112V160H448V112C448 85.49 426.51 64 400 64ZM0 464C0 490.5 21.5 512 48 512H400C426.5 512 448 490.5 448 464V192H0V464Z"/></svg>
                    </button>
                </div>



*/