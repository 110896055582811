import WidgetPower from './WidgetPower'
import { useContext } from 'react'
import { GlobalContext } from '../../app/context/glogal'



const WidgetPowerplant = ({ powerplant, hasFollowing = false, isFollowed = false, even = false, showYear = true }) => {
    const { techonologies } = useContext(GlobalContext)

    return (
        <div className={`powerplant${hasFollowing ? ' hasFollowing' : ''}${isFollowed ? ' isFollowed' : ''}${even ? ' isEven' : ' isOdd'}${powerplant.thermalPower < 501 ? ' power-1' : powerplant.thermalPower < 2499 ? ' power-2' : ' power-3'}${(new Date()).getTime() - (new Date(powerplant.buildDate)).getTime() < 0 ? ' futurebuild' : ''}`}>
            {showYear && (
                <h3 className="powerplant-builddate">
                    {(new Date(powerplant.buildDate)).getFullYear()}
                </h3>
            )}
            <h2 className="powerplant-name">{powerplant.name}</h2>
            <p className="powerplant-description">{powerplant.description}</p>
            <div className="powerplant-powers">
                <WidgetPower type="thermal" powerplant={powerplant} />
                {powerplant.electricPower > 0 && <WidgetPower type="electric" powerplant={powerplant} />}
            </div>
            <div className="powerplant-technologies">
                {powerplant.technologies.map( (technology, index) => (
                    <div key={`Technology${index}`} className="powerplant-technologies-item">
                        <img src={`/images/powerplants/technology-${technology}.png`} alt={techonologies[technology]}/>
                    </div>
                ))}
            </div>
        </div>
    )
}
export default WidgetPowerplant