import { useContext } from "react"
import { GlobalContext } from "../../app/context/glogal"



const Legend = () => {
    /**
     * Context
     */
    const { techonologies } = useContext(GlobalContext)

    /**
     * Render
     */
    return (
        <div className="powerplants-legend">
            {techonologies.map((technologie, index) => (
                <div 
                    key={`LegendsTechnologies${index}`} 
                    className="powerplants-legend-item"
                >
                    <div className="powerplants-legend-item-icon">
                        <img 
                            src={`/images/powerplants/technology-${index}.png`} 
                            alt={techonologies[technologie]} 
                        />
                    </div>
                    <div className="powerplants-legend-item-text">{technologie}</div>
                </div>
            ))}

            <div className="powerplants-legend-item">
                <div className="powerplants-legend-item-icon">
                    <img src="/images/powerplants/transformed.png" alt="Centrale transformée fossile -> renouvelable" />
                </div>
                <div className="powerplants-legend-item-text">Centrale transformée<br />fossile -&gt; renouvelable</div>
            </div>
            <div className="powerplants-legend-item">
                <div className="powerplants-legend-item-icon">
                    <img src="/images/powerplants/power-1.png" alt="Puissance > 500 kW" />
                </div>
                <div className="powerplants-legend-item-text">Puissance &lt; 500 kW</div>
            </div>
            <div className="powerplants-legend-item">
                <div className="powerplants-legend-item-icon">
                    <img src="/images/powerplants/power-2.png" alt="Puissance 500-2500 kW" />
                </div>
                <div className="powerplants-legend-item-text">Puissance 500-2500 kW</div>
            </div>
            <div className="powerplants-legend-item">
                <div className="powerplants-legend-item-icon">
                    <img src="/images/powerplants/power-3.png" alt="Puissance > 2500 kW" />
                </div>
                <div className="powerplants-legend-item-text">Puissance &gt; 2500 kW</div>
            </div>
            <div className="powerplants-legend-item">
                <div className="powerplants-legend-item-icon">
                    <img src="/images/powerplants/power-1-future-builddate.png" alt="Future construction Puissance > 500 kW" />
                </div>
                <div className="powerplants-legend-item-text">Future construction<br />Puissance &lt; 500 kW</div>
            </div>
            <div className="powerplants-legend-item">
                <div className="powerplants-legend-item-icon">
                    <img src="/images/powerplants/power-2-future-builddate.png" alt="Future construction Puissance 500-2500 kW" />
                </div>
                <div className="powerplants-legend-item-text">Future construction<br />Puissance 500-2500 kW</div>
            </div>
            <div className="powerplants-legend-item">
                <div className="powerplants-legend-item-icon">
                    <img src="/images/powerplants/power-3-future-builddate.png" alt="Future construction Puissance > 2500 kW" />
                </div>
                <div className="powerplants-legend-item-text">Future construction<br />Puissance &gt; 2500 kW</div>
            </div>
        </div>
    )
}

export default Legend