import React, { useContext } from 'react'
import WidgetPowerplant from './WidgetPowerplant'
import { GlobalContext } from '../../app/context/glogal'
import Legend from './Legend'



const ViewTechnology = () => {
    /**
     * Context
     */
    const { view, techonologies } = useContext(GlobalContext)
    /**
     * Render
     */
    return (
        <div className="view-technology">
            {view.powerplants?.length > 0 ? (
                <>
                    <div className="powerplants-title">
                        Centrales à base de technologie « {techonologies[view.technology]} »
                    </div>
                    <div className="powerplants-header">
                        <div className="powerplants-header-wrapper">
                            <div className="powerplants-header-bubble">
                                <img
                                    src={`/images/powerplants/technology-${view.technology}.png`} 
                                    alt="LuxEnergie"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="powerplants-grid">
                        {view.powerplants?.map((powerplant, index) => (
                            <WidgetPowerplant 
                                powerplant={powerplant} 
                                key={`Powerplant${index}`} 
                                hasFollowing={!!view.powerplants[index + 1]} 
                                isFollowed={index > 0} 
                                even={(index + 1) % 2 === 0}
                            />
                        ))}
                    </div>
                    <Legend />    
                </>
            ) : (
                <div className="powerplants-title">Aucune centrale à base de technologie « {techonologies[view.technology]} »</div>
            )}
        </div>
    )
}
export default ViewTechnology

