import React, { useContext, useEffect, useState } from 'react'
import WidgetPowerplant from './WidgetPowerplant'
import { GlobalContext } from '../../app/context/glogal'
import Legend from './Legend'



const ViewCalendar = () => {
    /**
     * Context
     */
    const { view } = useContext(GlobalContext)
    /**
     * Render
     */
    return (
        <div className="view-calendar">
            {view.powerplants?.length > 0 ? (
                <>
                    <div className="powerplants-title">
                        Évolution verte de LuxEnergie {view.start} - {view.end}
                    </div>
                    <div className="view-calendar-grid">
                        {view.years?.map((year, yearIndex) => (
                            <div 
                                key={`powerplants-year${yearIndex}`} 
                                className="view-calendar-year"
                            >
                                {Array.isArray(year) ? (
                                    <div className="view-calendar-year-empty" />
                                ) : (// Empty year ou range of year
                                    <>
                                        <div className="powerplants-header">
                                            <div className="powerplants-header-wrapper">
                                                <div className="powerplants-header-bubble">{year}</div>
                                            </div>
                                        </div>
                                        <div className="powerplants-grid">
                                            {view.groupByYear[year]?.map((powerplant, powerplantIndex) => (
                                                <WidgetPowerplant 
                                                    powerplant={powerplant} 
                                                    key={`powerplants-year${yearIndex}-${powerplantIndex}`} 
                                                    hasFollowing={!!view.groupByYear[year][powerplantIndex + 1]} 
                                                    isFollowed={powerplantIndex > 0} 
                                                    even={(powerplantIndex + 1) % 2 === 0}
                                                    showYear={false}
                                                />
                                            ))}
                                        </div>
                                    </>
                                )}
                            </div>
                        )) }
                    </div>
                    <Legend />    
                </>
            ) : (
                <div className="powerplants-title">
                    Aucune Évolution verte de LuxEnergie disponible
                </div>
            )}
        </div>
    )
}
export default ViewCalendar

