import { createContext, useEffect, useState } from "react"
import data from '../../data/powerplants'
import applyFilter from "../helpers/applyFilter"
import powerplantsGroupByYear from "../helpers/powerplantsGroupByYear"
import powerplantsRange from "../helpers/powerplantsRange"

/**
 * Context
 */
export const GlobalContext = createContext()

/**
 * Context provider
 */
const GlobalContextProvider = ({ children }) => {
    const techonologies = [
        `Chaudière biomasse`,
        `CPH Centrale à vapeur`,
        `Pompe à chaleur`,
        `CPH Gazéificateur`,
        `Collecteurs solaires`,
        `Collecteurs photovoltaïques`,
        `Chaleur fatale`,
    ]
    const filteredPowerplant = {}
    const [ powerplants, setPowerplants ] = useState([])
    const [ view, setView ] = useState({})
    const [ filters, setFilters ] = useState({
        search: '',
        technologies: techonologies.map((_, i) => i),
        powers: ['500', '500-2500', '2500'],
        view: {
            type: 'calendar',
        }
    })
    
    useEffect(() => {
        const powerplants = applyFilter(data, filters)
        switch (filters.view.type ) {
            case 'ordered': {
                return setView({ powerplants })
            } case 'calendar': {
                const gby = powerplantsGroupByYear(powerplants)
                const years = powerplantsRange(gby)
                return setView({
                    start: gby.firstBuildDate,
                    end: gby.lastBuildDate,
                    groupByYear: gby.groupByYear,
                    years,
                    powerplants,
                })
            } case 'technology' : {
                return setView({
                    technology: filters.view.technology,
                    powerplants: powerplants.filter( 
                        powerplant => powerplant.technologies.includes(filters.view.technology)
                    ),
                })
            }
        }
    }, [ filters ])



    return (
        <GlobalContext.Provider value={{
            techonologies,
            filters, setFilters,
            powerplants, setPowerplants,
            view, setView,
            data,
        }}>
            {children}
        </GlobalContext.Provider>
    )
}
export default GlobalContextProvider 