
/**
 * Return powerplants grouped by year
 */
const powerplantsGroupByYear = (powerplants) => {
    let firstBuildDate= Infinity
    let lastBuildDate = 0
    const groupByYear = {}
    powerplants.forEach(powerplant => {
        const year = (new Date(powerplant.buildDate)).getFullYear()
        firstBuildDate = year < firstBuildDate ? year : firstBuildDate
        lastBuildDate = year > lastBuildDate ? year : lastBuildDate
        groupByYear[year] = groupByYear[year] ? [ ...groupByYear[year], powerplant ] : [ powerplant ]
    })
    return { firstBuildDate, lastBuildDate, groupByYear }
}

export default powerplantsGroupByYear