import normalizeSearch from "./normalizeSearch"

const applyFilter = (data, filters) => {
    let powerplants = [ ...data ]
    // Technologies filter
    powerplants = powerplants.filter(
        powerplant => filters.technologies.some(
            technology => powerplant.technologies.includes(technology)
        )
    )
    // Search filter
    const searchTerm = normalizeSearch(filters.search)
    powerplants = powerplants.filter(
        powerplant => (
            normalizeSearch(powerplant.name).includes(searchTerm) ||
            normalizeSearch(powerplant.description).includes(searchTerm)
        ))
    // Power Filter
    powerplants = powerplants.filter(
        powerplant => {
            return (
                (filters.powers.includes('500') && powerplant.thermalPower < 499) ||
                (filters.powers.includes('500-2500') && powerplant.thermalPower > 499 && powerplant.thermalPower < 2501) ||
                (filters.powers.includes('2500') && powerplant.thermalPower > 2500)
            )
        }
    )
    // Order
    if (filters.view.order === 'DESC') powerplants = powerplants.reverse()
    return powerplants
}
export default applyFilter