import styles from './Header.module.scss'



const Header = ({  }) => {


    const menu = [
        { name: 'Link 1', path: '/' },
        { name: 'Link 2', path: '/' },
    ]

    return (
        <>
            {/* <header className={styles.header} style={{display: 'none'}}>
                <nav className={styles.headerNav}>
                    <div className={styles.headerNavLogo}>
                        <img
                            src={`/images/logo.png`} 
                            alt="LuxEnergie"
                        />
                    </div>
                    <ul className={styles.headerNavMenu}>
                        {menu.map((link, index) => (
                            <li key={`HeaderMenuItem${index}`} className={styles.headerNavMenuItem}>
                                <a className={styles.headerNavMenuItemLink}>
                                    {link.name}
                                </a>
                            </li>
                        ))}
                    </ul>
                </nav>
            </header> */}
        </>
    )
}
export default Header
