




const WidgetPower = ({ powerplant, type }) => {
    const futureBuild = (new Date()).getTime() - (new Date(powerplant.buildDate)).getTime() < 0
    return (
        <div className={`powerplant-power power-${type}`}>
            {type === 'thermal' ? (
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 530 530">
                    <g>
                        <path fill="#ffffff" className={powerplant.thermalPower < 501 || futureBuild ? 'power-1' : powerplant.thermalPower < 2499 ? 'power-2' : 'power-3'} d="M134.19,180.79c18.2,27.11,47.27,31.74,48.79,31.96c5.26,0.81,10.66-1.09,14.3-5.04
                            c2.17-2.37,49.35-55.1,25.11-152c43,6.02,75.63,23.01,97.23,50.65c45.88,58.75,28.76,148,28.59,148.87
                            c-1.2,5.99,1.05,12.17,5.86,15.96c4.82,3.8,11.31,4.54,16.88,1.97c1.51-0.71,29.54-13.97,51.2-41.2
                            c16.4,45.92,11.54,99.13-14.37,144.72c-30.64,53.91-83.79,86.41-142.14,86.94c-0.52,0.01-1.03,0.01-1.55,0.01
                            c-80.26,0-131.11-55.83-152.02-108.32C87.59,293.83,96.53,227.74,134.19,180.79z"/>
                        <path fill="#7ab929" d="M264.24,491.7c0.56,0,1.12-0.01,1.69-0.01c8.42-0.08,16.74-0.73,24.91-1.94l30.97-7.46
                            c43.85-14.62,81.71-45.76,106.45-89.25c12.74-22.4,20.87-46.5,24.54-70.85l2.19-30.97c-0.24-32.38-8.44-64.23-24.8-92.26
                            c-2.97-5.07-8.47-8.01-14.4-7.64c-5.85,0.39-10.99,4.05-13.26,9.46c-5.68,13.58-15.02,24.43-24.05,32.52
                            c1.31-34.88-3.23-89.46-36.77-132.52c-29.97-38.49-76.39-59.5-138-62.46c-5.31-0.14-10.07,2.08-13.14,6.2
                            c-3.09,4.11-3.91,9.5-2.22,14.35c24.08,69.18,4.98,112.51-5.31,129.02c-7.77-3.55-18.89-11.51-23.41-28.64
                            c-1.4-5.27-5.44-9.41-10.67-10.93c-5.2-1.53-10.85-0.18-14.85,3.53c-57.72,53.66-74.94,138.81-43.85,216.93
                            C120.51,444.73,187.06,491.7,264.24,491.7z M140.18,190.71c17.37,25.88,45.12,30.3,46.58,30.52c5.02,0.78,10.18-1.04,13.65-4.81
                            c2.08-2.27,47.11-52.6,23.97-145.12c41.05,5.74,72.2,21.97,92.82,48.36c43.8,56.09,27.46,141.29,27.29,142.12
                            c-1.15,5.72,1.01,11.62,5.59,15.23c4.6,3.63,10.8,4.33,16.11,1.88c1.44-0.67,28.2-13.33,48.88-39.33
                            c15.65,43.84,11.02,94.64-13.72,138.16c-29.25,51.47-80,82.49-135.7,83c-0.5,0.01-0.98,0.01-1.48,0.01
                            c-76.62,0-125.17-53.3-145.13-103.42C95.69,298.63,104.22,235.54,140.18,190.71z"/>
                    </g>
                </svg>
            ) : (
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 530 530">
                    <polygon fill="#FFFFFF" className={powerplant.thermalPower < 501 || futureBuild ? 'power-1' : powerplant.thermalPower < 2499 ? 'power-2' : 'power-3'} points="177.72,23.06 320.38,23.06 278.48,186.67 409.16,186.67 241.56,495.93 249.54,293.42 121.85,288.43 "/>
                    <g>
                        <path fill="#7AB929" d="M221.98,504.27c-0.57,6.36,3.38,12.25,9.47,14.14c1.32,0.4,2.67,0.6,3.99,0.6c4.8,0,9.37-2.58,11.8-6.94
                            l19.12-34.33v0.6l27.03-48.77v-0.35L331.96,360l92.55-166.96c0.96-3.48,0.51-7.24-1.36-10.42c-2.43-4.14-6.86-6.68-11.65-6.68
                            h-73.21c-7.46,0-13.52,6.06-13.52,13.52c0,7.47,6.06,13.52,13.52,13.52h50.21L254.67,443.2l13.72-150.85
                            c0.34-3.78-0.92-7.53-3.48-10.33c-2.56-2.8-6.18-4.41-9.98-4.41h-120l46.89-239.58h123.37l-46.44,176.44
                            c-1.9,7.21,2.41,14.61,9.64,16.51c7.23,1.93,14.61-2.42,16.51-9.63l50.9-193.4c1.07-4.06,0.2-8.36-2.37-11.69
                            c-2.56-3.31-6.5-5.26-10.69-5.26H170.69c-6.47,0-12.03,4.57-13.26,10.92l-52.17,266.62c-0.79,3.97,0.24,8.07,2.82,11.18
                            c2.57,3.12,6.4,4.92,10.44,4.92h121.61L221.98,504.27z"/>
                    </g>
                </svg>
            )}
            <span className="power">{type === 'thermal' || futureBuild ? powerplant.thermalPower : powerplant.electricPower}</span>
            <span className="unit">
                kW
                <sub className="type">
                    {type === 'thermal' ? 'th' : 'el'}
                </sub>
            </span>
        </div>
    )
}
export default WidgetPower