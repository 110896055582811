

/**
 * Return powerplant years range
 */
const powerplantsRange = ({ firstBuildDate, lastBuildDate, groupByYear }) => {
    const years = []
    let year = firstBuildDate
    while (year <= lastBuildDate) {
        if (groupByYear[year]) {
            years.push(year)
        } else {
            const range = [ year ]
            let end = false
            while (++year <= lastBuildDate && !groupByYear[year]) {
                end = year
            }
            --year
            if (end) range.push(end)
            years.push(range)
        }
        year++
    }
    return years
}
export default powerplantsRange