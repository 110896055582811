import Footer from './Footer'
import Header from './Header'
import Main from './Main'
import styles from './Layout.module.scss'


const Layout = ({ children }) => {
    return (
        <>
            {/* <Head>
                <title>LuxEnergie</title>
                <link rel="icon" href="/favicon.ico" />
            </Head> */}
            <div className={styles.layout}>
                <Header />
                <Main>{children}</Main>
                <Footer />
            </div>
        </>
    )
}
export default Layout