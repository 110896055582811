import GlobalContextProvider from './app/context/glogal'
import Layout from './components/layout/Layout'
import Home from './pages/Home'
import './styles/globals.scss'

const App = () => {
    return (
        <GlobalContextProvider>
            <Layout>
                <Home />
            </Layout>
        </GlobalContextProvider>
    )
}

export default App;
