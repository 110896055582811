import React, { useContext, useEffect, useState } from 'react'
import Filter from '../components/ui/Filter'
import { GlobalContext } from '../app/context/glogal'
import ViewCalendar from '../components/ui/ViewCalendar'
import ViewTechnology from '../components/ui/ViewTechnology'



const Index = () => {
    /**
     * Context
     */
    const { filters } = useContext(GlobalContext)

    /**
     * Render
     */
    return (
        <div className="container">
            <Filter />
            {filters.view.type === 'calendar' && <ViewCalendar />}
            {filters.view.type === 'technology' && <ViewTechnology />}
        </div>
    )
}
export default Index

