import { v4 as uuid } from 'uuid'




const getYearIso = (year) => (new Date(year, 1, 1)).toISOString()
let order = 0

const powerplants = [
    {
        id: uuid(),
        name: 'Erpeldange',
        buildDate: getYearIso(2006),
        description: 'ALA',
        order: order++,
        thermalPower: 320,
        electricPower: 0,
        technologies: [ 0 ],
        status: 1,
    },
    {
        id: uuid(),
        name: 'Differdange',
        buildDate: getYearIso(2012),
        description: 'Funiculaire',
        order: order++,
        thermalPower: 1060,
        electricPower: 0,
        technologies: [ 0 ],
        status: 1,
    },
    {
        id: uuid(),
        name: 'Roost',
        buildDate: getYearIso(2012),
        description: 'Kiowatt',
        order: order++,
        thermalPower: 11500,
        electricPower: 2700,
        technologies: [ 2 ],
        status: 1,
    },
    {
        id: uuid(),
        name: 'Echternach',
        buildDate: getYearIso(2013),
        description: 'Alferweiher',
        order: order++,
        thermalPower: 224,
        electricPower: 0,
        technologies: [ 0, 4 ],
        status: 1,
    },
    {
        id: uuid(),
        name: 'Sandweiler',
        buildDate: getYearIso(2013),
        description: '',
        order: order++,
        thermalPower: 220,
        electricPower: 0,
        technologies: [ 0 ],
        status: 0,
    },
    {
        id: uuid(),
        name: 'Strassen',
        buildDate: getYearIso(2014),
        description: 'Pescher',
        order: order++,
        thermalPower: 720,
        electricPower: 0,
        technologies: [ 0 ],
        status: 1,
    },
    {
        id: uuid(),
        name: 'Schengen',
        buildDate: getYearIso(2015),
        description: `Les jardins de Schengen`,
        order: order++,
        thermalPower: 370,
        electricPower: 0,
        technologies: [ 0 ],
        status: 1,
    },
    {
        id: uuid(),
        name: 'Strassen',
        buildDate: getYearIso(2015),
        description: 'Gazéificateur',
        order: order++,
        thermalPower: 260,
        electricPower: 165,
        technologies: [ 0 ],
        status: 1,
    },
    {
        id: uuid(),
        name: 'Luxembourg',
        buildDate: getYearIso(2017),
        description: `Extension centrale d'énergie Kirchberg`,
        order: order++,
        thermalPower: 9500,
        electricPower: 2800,
        technologies: [ 2 ],
        status: 0,
    },
    {
        id: uuid(),
        name: 'Bettembourg',
        buildDate: getYearIso(2018),
        description: '',
        order: order++,
        thermalPower: 2000,
        electricPower: 330,
        technologies: [ 0, 2 ],
        status: 2,
    },
    {
        id: uuid(),
        name: 'Kockelscheuer',
        buildDate: getYearIso(2020),
        description: `Nouveau Stade`,
        order: order++,
        thermalPower: 800,
        electricPower: 0,
        technologies: [ 0 ],
        status: 1,
    },
    {
        id: uuid(),
        name: 'Dudelange',
        buildDate: getYearIso(2021),
        description: `Brill`,
        order: order++,
        thermalPower: 1800,
        electricPower: 0,
        technologies: [ 0 ],
        status: 0,
    },
    {
        id: uuid(),
        name: 'Differdange',
        buildDate: getYearIso(2021),
        description: `Woiwer Wissen`,
        order: order++,
        thermalPower: 1000,
        electricPower: 0,
        technologies: [ 0 ],
        status: 1,
    },
    {
        id: uuid(),
        name: 'Niederanven',
        buildDate: getYearIso(2022),
        description: ``,
        order: order++,
        thermalPower: 1000,
        electricPower: 190,
        technologies: [ 0 ],
        status: 2,
    },
    {
        id: uuid(),
        name: 'Niederanven',
        buildDate: getYearIso(2022),
        description: ``,
        order: order++,
        thermalPower: 790,
        electricPower: 190,
        technologies: [ 0, 1 ],
        status: 2,
    },
    {
        id: uuid(),
        name: 'Niederanven',
        buildDate: getYearIso(2022),
        description: ``,
        order: order++,
        thermalPower: 790,
        electricPower: 190,
        technologies: [ 0, 1 ],
        status: 2,
    },
    {
        id: uuid(),
        name: 'Remich',
        buildDate: getYearIso(2022),
        description: ``,
        order: order++,
        thermalPower: 790,
        electricPower: 190,
        technologies: [ 0, 1 ],
        status: 2,
    },
    {
        id: uuid(),
        name: 'Niedercorn',
        buildDate: getYearIso(2022),
        description: `Lotissement Mathendahl`,
        order: order++,
        thermalPower: 1000,
        electricPower: 0,
        technologies: [ 0 ],
        status: 1,
    },
    {
        id: uuid(),
        name: 'Lintgen',
        buildDate: getYearIso(2022),
        description: ``,
        order: order++,
        thermalPower: 500,
        electricPower: 0,
        technologies: [ 0 ],
        status: 0,
    },
    {
        id: uuid(),
        name: 'Mamer',
        buildDate: getYearIso(2023),
        description: ``,
        order: order++,
        thermalPower: 1690,
        electricPower: 190,
        technologies: [ 0, 1 ],
        status: 1,
    },
    {
        id: uuid(),
        name: 'Howald',
        buildDate: getYearIso(2023),
        description: ``,
        order: order++,
        thermalPower: 1290,
        electricPower: 190,
        technologies: [ 0, 1 ],
        status: 1,
    },
    {
        id: uuid(),
        name: 'Luxembourg',
        buildDate: getYearIso(2023),
        description: `Auchan`,
        order: order++,
        thermalPower: 3600,
        electricPower: 0,
        technologies: [ 3 ],
        status: 1,
    },
    {
        id: uuid(),
        name: 'Luxembourg',
        buildDate: getYearIso(2023),
        description: `Transformation centrale d'énergie Kirchberg`,
        order: order++,
        thermalPower: 15000,
        electricPower: 0,
        technologies: [ 0 ],
        status: 1,
    },
    {
        id: uuid(),
        name: 'Rodange',
        buildDate: getYearIso(2024),
        description: `Croix St. Pierre`,
        order: order++,
        thermalPower: 500,
        electricPower: 0,
        technologies: [ 0 ],
        status: 1,
    },

]
export default powerplants